import React from "react";
import PropTypes from "prop-types";
import { sortBy } from "lodash";

import { primaryNavHeightObs, primaryNavWidthObs } from "primary-navbar!sofe";
import { UserTenantProps, useHasAccess } from "cp-client-auth!sofe";

import SettingsMenuItem from "./settings-menu-item.component";
import SettingsSubmenuItem from "./settings-submenu-item.component";
import SettingsMenuContainer from "./settings-menu-container.component";
import styles from "./settings-menu.styles.css";
import { getAdminMenuItems, getSettingsMenuItems } from "./settings-menu.items.js";

export default function SettingsMenu(props) {
  const hasOnboarding = useHasAccess("canopy_onboarding");
  return <SettingsMenuClass {...props} hasOnboarding={hasOnboarding} />;
}

@UserTenantProps({
  waitForData: true,
  permissions: {
    hasCompanySettingsCompanyInfo: "company_settings_company_info",
  },
  betas: {
    hasRenameBeta: "files_automation_rename",
  },
})
class SettingsMenuClass extends React.Component {
  static propTypes = {
    loggedInUser: PropTypes.object,
  };

  state = {
    adminMenuItems: [],
    navTop: 0,
    settingsMenuItems: [],
    navSide: 0,
  };

  componentDidMount() {
    this.disposableHeight = primaryNavHeightObs.subscribe((height) => {
      this.setState({ navTop: height });
    });

    this.disposableWidth = primaryNavWidthObs.subscribe((width) => {
      this.setState({ navSide: width });
    });

    const { loggedInUser, hasOnboarding, betas } = this.props;

    const adminMenuItems = getAdminMenuItems({ loggedInUser, hasOnboarding, hasRenameBeta: betas?.hasRenameBeta });
    const settingsMenuItems = getSettingsMenuItems({ loggedInUser });

    this.setState(() => ({ adminMenuItems, settingsMenuItems }));
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.hasOnboarding !== this.props.hasOnboarding ||
      prevProps.betas?.hasRenameBeta !== this.props.betas?.hasRenameBeta
    ) {
      const { loggedInUser, hasOnboarding, betas } = this.props;
      this.setState({
        adminMenuItems: getAdminMenuItems({ loggedInUser, hasOnboarding, hasRenameBeta: betas?.hasRenameBeta }),
      });
    }
  }

  componentWillUnmount() {
    if (this.disposableHeight) {
      this.disposableHeight.unsubscribe();
    }

    if (this.disposableWidth) {
      this.disposableWidth.unsubscribe();
    }
  }

  render() {
    const { loggedInUser } = this.props;
    const { navTop, navSide } = this.state;
    const dynamicHeight = (navTop || 0) + 56;

    const settingsMenuItems = this.state.settingsMenuItems.filter((item) => !!item.shouldDisplay);
    const adminMenuItems = this.state.adminMenuItems.filter((item) => !!item.shouldDisplay);
    const hasAdminMenuItems = adminMenuItems.length > 0;
    const { hasCompanySettingsCompanyInfo } = this.props.permissions;
    return (
      <div
        className={styles.sidebar}
        style={{
          height: `calc(100% - ${dynamicHeight}px)`,
          top: dynamicHeight,
          marginLeft: navSide,
        }}
      >
        {(hasAdminMenuItems || hasCompanySettingsCompanyInfo) && (
          <SettingsMenuContainer>
            <SettingsMenuItem
              icon="misc-buildings"
              shouldDisplay={hasCompanySettingsCompanyInfo}
              title="Company"
              url={"#/global-settings/company"}
            />
            {sortBy(adminMenuItems, "title").map((menuItem) => (
              <SettingsMenuItem key={menuItem.title} {...menuItem} />
            ))}
          </SettingsMenuContainer>
        )}

        <SettingsMenuContainer>
          <SettingsMenuItem icon="person" title="Profile" url={`#/global-settings/user/${loggedInUser.id}`} />
          {sortBy(settingsMenuItems, "title").map((menuItem) => (
            <SettingsMenuItem key={menuItem.title} {...menuItem}>
              {menuItem.subItems &&
                sortBy(menuItem.subItems, "title").map((subItem) => (
                  <SettingsSubmenuItem key={subItem.title} {...subItem} />
                ))}
            </SettingsMenuItem>
          ))}
        </SettingsMenuContainer>
      </div>
    );
  }
}
